// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import { useForm } from "react-hook-form";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useLoggedIn } from "../hooks"

async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });
  console.log({ response })

  return response.json();
}


const Login: React.FC<PageProps> = ({
  location,
}) => {
  const { isLoggedIn, setCredentials, logout } = useLoggedIn()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => postData('/api/auth', data)
    .then(data => {
      const { url, key } = data;
      if (url && key) {
        setCredentials(data)
      }


    });


  return (
    <Layout title="Login" location={location}>
      {!isLoggedIn &&
        (<form onSubmit={handleSubmit(onSubmit)}>

          <input defaultValue="" placeholder="username" {...register("username", { required: true })} />


          <input {...register("password", { required: true })} placeholder="password" type="password" />
          {/* errors will return when field validation fails  */}
          {errors.exampleRequired && <span>This field is required</span>}

          <input type="submit" value="log in" />
        </form>)
      }

      {isLoggedIn && (
        <div>
          <p>You're logged in</p>
          <button onClick={logout} >Log out</button>
        </div>
      )}

    </Layout>
  )
}

export default Login

